import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import getTokens from "@kiwicom/orbit-components/lib/getTokens";
import ThemeProvider from "@kiwicom/orbit-components/lib/ThemeProvider";
const customTokens = getTokens({
  base: {
    fontFamily:
      '"Encode Sans", -apple-system, ".SFNSText-Regular", "San Francisco", "Segoe UI", "Helvetica Neue", "Lucida Grande", sans-serif;',
    fontWeightNormal: "500",
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={{ orbit: customTokens }}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
