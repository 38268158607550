import "./App.css";
import {
  ButtonLink,
  useMediaQuery,
  NavigationBar,
  LinkList,
  Drawer,
  TextLink,
  Text,
  Stack,
  Grid,
  Heading,
  CallOutBanner,
} from "@kiwicom/orbit-components";
import {
  Anywhere,
  CheckCircle,
  DeviceMobile,
  Diamond,
  Email,
  Search,
  Security,
  Settings,
  TermsAndConditions,
  Trip,
} from "@kiwicom/orbit-components/icons";
import React from "react";
import Features from "./Features";

function App() {
  const [showDrawer, setShowDrawer] = React.useState(false);
  const { isLargeMobile } = useMediaQuery();
  return (
    <>
      {showDrawer && (
        <Drawer
          onClose={() => {
            setShowDrawer(false);
          }}
          shown={showDrawer}
        >
          <LinkList>
            <TextLink type="secondary">English</TextLink>
            <TextLink type="secondary">Help</TextLink>
            <TextLink type="secondary">My account</TextLink>
          </LinkList>
        </Drawer>
      )}
      <NavigationBar
      // onMenuOpen={isLargeMobile ? undefined : () => setShowDrawer(true)}
      >
        <div className="container">
          <Stack
            direction="row"
            align="center"
            justify={isLargeMobile ? "center" : "start"}
          >
            <ButtonLink href="https://softmile.com">
              <div style={{ maxWidth: "200px", overflow: "hidden" }}>
                <img
                  src={require("./assets/images/logo.png").default}
                  alt="Softmile.com"
                  height="40px"
                />
              </div>
            </ButtonLink>
            {/*    <ButtonLink
            type="secondary"
            iconLeft={<AirplaneTakeoff />}
            title="Travel"
          />
          <ButtonLink
            type="secondary"
            iconLeft={<Accommodation />}
            title="Rooms"
          />
          <ButtonLink
            type="secondary"
            iconLeft={<Partners />}
            title="Careers"
          /> */}
            {isLargeMobile && (
              <Stack justify="end" inline>
                <Text type="critical">
                  <i>"Software Art"</i>
                </Text>
              </Stack>
            )}
          </Stack>
        </div>
      </NavigationBar>
      <div
        className="hero hero-div"
        style={{
          marginTop: isLargeMobile ? "63px" : "52px",
        }}
      >
        <div className={isLargeMobile ? "container" : ""}>
          <Grid columns={isLargeMobile ? "1fr 1fr" : "1fr"} gap="40px">
            <Stack direction="row">
              <br />
              <Text>
                {" "}
                <h4 style={{ color: "#ae328b" }}>Hi there, I'm Sedat</h4>
              </Text>
              <Heading type="display">
                <span style={{ color: "#ae328b" }}>Freelance </span>
                web developer & designer
              </Heading>
              <br />
              <Text>
                <p
                  style={{
                    fontSize: "16px",

                    textAlign: "justify",
                    lineHeight: "28px",
                  }}
                >
                  I am full stack creative freelance web designer and web
                  developer based in Sydney. As an experienced freelance web
                  designer and web developer, I specialise in design and
                  development of world-class website interfaces and memorable
                  visual identity.
                </p>
              </Text>
            </Stack>

            <img
              src={require("./assets/images/hero-image.png").default}
              alt="developer"
              className="banner"
            />
          </Grid>
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#fff",

          padding: "40px 10px",
          borderTop: "1px solid #ddd",
        }}
      >
        <div
          className={isLargeMobile ? "container" : ""}
          style={{
            textAlign: "center",
            marginTop: isLargeMobile ? "40px" : "",
          }}
        >
          <Heading type="display">
            <p style={{ color: "#555" }}>Web Developer Sydney</p>
          </Heading>
          <br />
          <Grid columns={isLargeMobile ? "1fr 1fr 1fr" : "1fr"} gap="20px">
            <Stack direction="row">
              <div className="kart">
                <Text>
                  <h1 className="kolonlar">Creativity.</h1>
                </Text>
                <Text>
                  <p
                    style={{
                      fontSize: "17px",
                      color: "#666",

                      lineHeight: "28px",
                    }}
                  >
                    A great website starts with great design. I create stunning
                    websites that showcase your services or products.
                  </p>
                </Text>
              </div>
            </Stack>
            <Stack direction="row">
              <div className="kart">
                <Text>
                  <h1 className="kolonlar">Experience.</h1>
                </Text>
                <Text>
                  <p
                    style={{
                      fontSize: "17px",
                      color: "#666",

                      lineHeight: "28px",
                    }}
                  >
                    Freelance web designer and developer with 12 years of
                    experience. Check my portfolio to find out.
                  </p>
                </Text>
              </div>
            </Stack>
            <Stack direction="row">
              <div className="kart">
                <Text>
                  <h1 className="kolonlar">Quality.</h1>
                </Text>
                <Text>
                  <p
                    style={{
                      fontSize: "17px",
                      color: "#666",

                      lineHeight: "28px",
                    }}
                  >
                    Agency quality websites at an affordable price. Get in touch
                    with me to create your dream project.
                  </p>
                </Text>
              </div>
            </Stack>
          </Grid>
        </div>
      </div>
      <div>
        <div className={isLargeMobile ? "container" : "mobile-container"}>
          <Grid columns={isLargeMobile ? "1fr 1fr" : "1fr"} gap="40px">
            <img
              src={require("./assets/images/developer.jpg").default}
              alt="developer"
              className="banner"
            />
            <Stack direction="row">
              <br />
              <Text>
                <h4 style={{ color: "#ae328b" }}>My Skills</h4>
              </Text>
              <Grid columns="1fr 1fr" gap="5px">
                <CallOutBanner
                  onClick={() => {}}
                  description="Perfectly Single Page Web Applications"
                  illustration={
                    <img
                      src={require("./assets/images/react.png").default}
                      alt="react"
                      width="48"
                    />
                  }
                  title="React JS"
                />
                <CallOutBanner
                  onClick={() => {}}
                  description="Backend REST Api Services"
                  illustration={
                    <img
                      src={require("./assets/images/core.png").default}
                      alt="react"
                      width="48"
                    />
                  }
                  title=".Net Core Web Api"
                />
                <CallOutBanner
                  onClick={() => {}}
                  description="Wordpress / React, E-Commerce Sites"
                  illustration={
                    <img
                      src={require("./assets/images/online-shop.png").default}
                      alt="react"
                      width="48"
                    />
                  }
                  title="E-Commerce"
                />
                <CallOutBanner
                  onClick={() => {}}
                  description="Javascript Runtime for Applications"
                  illustration={
                    <img
                      src={require("./assets/images/node.jpg").default}
                      alt="react"
                      width="48"
                    />
                  }
                  title="Node JS"
                />
                <CallOutBanner
                  onClick={() => {}}
                  description="The Best Web Creation Platform"
                  illustration={
                    <img
                      src={require("./assets/images/wordpress.png").default}
                      alt="react"
                      width="48"
                    />
                  }
                  title="Wordpress"
                />

                <CallOutBanner
                  onClick={() => {}}
                  description="Basic Website Programming Languages"
                  illustration={
                    <img
                      src={require("./assets/images/htmlcssjs.png").default}
                      alt="react"
                      width="48"
                    />
                  }
                  title="Html / Css / Javascript"
                />
              </Grid>
            </Stack>
          </Grid>
        </div>
      </div>
      <div className="purple-bg">
        <Heading type="display">
          <p style={{ color: "#fff" }}>How can I help you?</p>
        </Heading>
        <br />
        <div className="container">
          <Grid columns={isLargeMobile ? "1fr 1fr 1fr 1fr" : "1fr"} gap="20px">
            <Stack direction="row">
              <div className="kart2">
                <img
                  src={require("./assets/images/briefcase.png").default}
                  alt="react"
                  width="48"
                />
                <Text>
                  <p className="kolonlar2">
                    Business Web Sites Design & Development
                  </p>
                </Text>
                <Text>
                  <p
                    style={{
                      fontSize: "17px",
                      color: "#666",

                      lineHeight: "28px",
                    }}
                  >
                    Websites to increase your new enquiries: marketing and
                    brochure websites.
                  </p>
                </Text>
              </div>
            </Stack>
            <Stack direction="row">
              <div className="kart2">
                <img
                  src={require("./assets/images/apps.png").default}
                  alt="react"
                  width="48"
                />
                <Text>
                  <p className="kolonlar2">Web Apps Design & Development</p>
                </Text>
                <Text>
                  <p
                    style={{
                      fontSize: "17px",
                      color: "#666",

                      lineHeight: "28px",
                    }}
                  >
                    Custom built web apps and portals that solve your business
                    problem, or make your new idea a reality.
                  </p>
                </Text>
              </div>
            </Stack>
            <Stack direction="row">
              <div className="kart2">
                <img
                  src={require("./assets/images/online-shop.png").default}
                  alt="react"
                  width="48"
                />
                <Text>
                  <p className="kolonlar2">Online Shops & E-Commerce</p>
                </Text>
                <Text>
                  <p
                    style={{
                      fontSize: "17px",
                      color: "#666",

                      lineHeight: "28px",
                    }}
                  >
                    Custom built e-commerce solutions that work quickly,
                    securely and look amazing.
                  </p>
                </Text>
              </div>
            </Stack>
            <Stack direction="row">
              <div className="kart2">
                <img
                  src={require("./assets/images/react.png").default}
                  alt="react"
                  width="48"
                />
                <Text>
                  <p className="kolonlar2">React Development</p>
                </Text>
                <Text>
                  <p
                    style={{
                      fontSize: "17px",
                      color: "#666",

                      lineHeight: "28px",
                    }}
                  >
                    I'm an React developer and create bespoke web portals,
                    dashboard and web apps.
                  </p>
                </Text>
              </div>
            </Stack>
          </Grid>
        </div>
      </div>

      <br />
      <div className="container">
        <Text>
          <p className="kolonlar2">
            What you'll get as standard & advanced features
          </p>
        </Text>
        <Grid columns={isLargeMobile ? "1fr 1fr" : "1fr"} gap="15px">
          <Features
            iconu={<Diamond className="mor" />}
            title="Expertly Designed"
            description={
              <Text type="secondary" size="large">
                I make beautiful websites designed exactly for your needs. Your
                website will seamlessly integrate with your existing brand.
              </Text>
            }
          />
          <Features
            iconu={<DeviceMobile className="mor" />}
            title="Mobile & Tablet Friendly"
            description={
              <Text type="secondary" size="large">
                Your site will be mobile friendly and will adapt layout to any
                device or screen resolution.
              </Text>
            }
          />
          <Features
            iconu={<Search className="mor" />}
            title="Search Engine Compatibility"
            description={
              <Text type="secondary" size="large">
                All my websites are built to be found in searches. Your site
                will be coded so that it will be fast and rank well in Google.
              </Text>
            }
          />
          <Features
            iconu={<TermsAndConditions className="mor" />}
            title="Easy to Edit Yourself"
            description={
              <Text type="secondary" size="large">
                You'll be able to make quick content changes to your site by
                logging into your website's control panel.
              </Text>
            }
          />
          <Features
            iconu={<Trip className="mor" />}
            title="Domain Registration"
            description={
              <Text type="secondary" size="large">
                Don't worry if you haven't got an address for your website yet.
                I can help you choose one and get it all set up to work with
                your website.
              </Text>
            }
          />
          <Features
            iconu={<Settings className="mor" />}
            title="Hosting Setup"
            description={
              <Text type="secondary" size="large">
                You can just leave the hosting of your website with me. I'll let
                you know the annual fee and I will set it up and maintain the
                hosting so that you don't have to.
              </Text>
            }
          />
          <Features
            iconu={<Security className="mor" />}
            title="Free SSL"
            description={
              <Text type="secondary" size="large">
                Your site will be secured with a SSL certificate - which gives
                you the padlock in the address bar and provides a secure
                connection when users are sending data through the site. Google
                also ranks sites with a security certificate higher than others.
              </Text>
            }
          />
          <Features
            iconu={<Email className="mor" />}
            title="Email Setup"
            description={
              <Text type="secondary" size="large">
                You can have domain email addresses (you@yourdomain.com.au) for
                all your staff, I'll set them up and let you know the login
                details.
              </Text>
            }
          />
          <Features
            iconu={<CheckCircle className="mor" />}
            title="Online Quizzes & Results"
            description={
              <Text type="secondary" size="large">
                Give your users a set of questions, take their answers and work
                out a result. Online questionnaires can be fantastic for market
                research or to recommend the right product.
              </Text>
            }
          />
          <Features
            iconu={<Anywhere className="mor" />}
            title="Multilingual Websites"
            description={
              <Text type="secondary" size="large">
                If your target audience speaks in a language other than English
                you can operate a multilingual version of your site. If you are
                looking for something simple you could offer Google Translate or
                for more control you can have a fully translated set of text
                specifically for each language.
              </Text>
            }
          />
        </Grid>
      </div>
      <br />
      <br />
      {/* <div className="customers-bg">
        <div className="container customers-div">
          <Heading type="title3">
            <span className="mor">HAPPY CUSTOMERS</span>
          </Heading>
          <Heading type="title1">Some of My Clients</Heading>
          <br />
          <Grid
            columns={isLargeMobile ? "repeat(5,1fr)" : "repeat(2,1fr)"}
            gap="15px"
          >
            <div className="customers-card">
              <img
                src={require("./assets/images/hypo.jpg").default}
                alt="logo"
                width="100%"
              />
            </div>
            <div className="customers-card">
              <img
                src={require("./assets/images/tgb.jpg").default}
                alt="logo"
                width="100%"
              />
            </div>
            <div className="customers-card">
              <img
                src={require("./assets/images/dekolon.jpg").default}
                alt="logo"
                width="100%"
              />
            </div>
            <div className="customers-card">
              <img
                src={require("./assets/images/greatexim.jpg").default}
                alt="logo"
                width="100%"
              />
            </div>

            <div className="customers-card">
              <img
                src={require("./assets/images/galata.jpg").default}
                alt="logo"
                width="100%"
              />
            </div>
          </Grid>
        </div>
      </div> */}
      <br />
      <div
        style={{
          textAlign: "center",
          margin: "-24px 0 0 0",
          backgroundColor: "#252525",
        }}
      >
        <div style={{ padding: "40px", color: "white" }}>
          <Heading as="h1" type="display">
            <b style={{ color: "white" }}> Let's Work Together</b>
          </Heading>
          <Stack direction="row" align="center" justify="center">
            <br />
          </Stack>
          <Stack direction="row" align="center" justify="center">
            <Email />
            <Text size="large" type="white">
              sedat@softmile.com
            </Text>
            <br />
          </Stack>
          <hr />
          <p style={{ fontWeight: "lighter" }}>Copyright Softmile , © 2021</p>
        </div>
      </div>
    </>
  );
}

export default App;
