import React from "react";
import { Heading, Stack } from "@kiwicom/orbit-components";

const Features = ({ iconu, title, description }) => {
  return (
    <div className="kart">
      <Stack direction="row" spacing="XSmall" align="center">
        {iconu}
        <Heading type="title3">
          <b>{title}</b>
        </Heading>
      </Stack>
      <p>{description}</p>
    </div>
  );
};

export default Features;
